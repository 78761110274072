import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";

// Create a new store instance.
const store = createStore({
    // strict: true,
    state() {
        return {
            isLoggedin: false,
            user: null,
            baseUrl: null,
            entity: "tramontane",
            userPermissionsOptions: [
                { value: "admin", label: "Admin" },
                { value: "child", label: "Gestion des enfants" },
                { value: "staff", label: "Gestion du personnel" },
                { value: "shared", label: "Gestion des documents partagés" },
                {
                    value: "personaldata",
                    label: "Gestion des documents du personnel",
                },
                { value: "garde", label: "Gestion des gardes" },
                { value: "forum", label: "Accès au forum" },
                { value: "pv", label: "Gestion des PV" },
                { value: "valves", label: "Gestion des valves" },
                { value: "stagiaire", label: "Stagiaire" },
                { value: "staffgarde", label: "Personnel de garde" },
                { value: "medicale", label: "Gestion Médicale" },
                { value: "overtime", label: "Gestion heures supplémentaires" },
                { value: "sorti", label: "Personnel de sorti" },
                // {'value':'notifications', 'label':'Notifier nouveaux documents'},
                // {'value':'notifygarde', 'label':'Notification des rendez-vous'},
                // {'value':'observation', 'label':'Historique des observations'}
            ],
            childGroupesOptions: [
                { value: "pe", label: "Petits" },
                { value: "mo", label: "Moyens" },
                { value: "ad", label: "Ados" },
                { value: "sf", label: "Suivis familles" },
                { value: "sa", label: "Suivis apparts" },
                { value: "au", label: "Accueils d'urgence" },
                { value: "be", label: "Bénéficiaires sortis" },
                { value: "ca", label: "En cours d'admission" },
            ],
            filesCategoryOptions: [
                { value: "ni", label: "Notes internes" },
                { value: "ts", label: "Courriers divers" },
                { value: "ps", label: "Rapports" },
                { value: "fi", label: "Pièces officielles" },
                { value: "sc", label: "Scolaires" },
                { value: "me", label: "Medicale" },
            ],
            weekOptions: [
                { value: "lundi", label: "Lundi" },
                { value: "mardi", label: "Mardi" },
                { value: "mercredi", label: "Mercredi" },
                { value: "jeudi", label: "Jeudi" },
                { value: "vendredi", label: "Vendredi" },
                { value: "samedi", label: "Samedi" },
                { value: "dimanche", label: "Dimanche" },
            ],
            fileUsersWatchedAcces: [
                "bruno@weblartiste.be",
                "serge.lahaye@latramontane.be",
                "julie.baeselen@latramontane.be",
                "karen.marchadour@latramontane.be",
                "jeremy.collet@latramontane.be",
                "joseph1982@gmail.com",
            ],
            allChildsList: [],
            allUsersList: [],
            allFilesList: [],
            dataSave: "",
            showCalendar: true,
            showCalendarGardes: true,
            showCalendarFiles: true,
            ignoreWarning: false,
            allForumPostsList: [],
            isForumNewPosts: false,
            activeForumNewPostsList: [],
            isForumNewPostComments: false,
            activeForumNewPostCommentsList: [],
        };
    },
    mutations: {
        setUser(state, data) {
            state.user = data;
        },
        isLoggedin(state, data) {
            state.isLoggedin = data;
        },
        setUsers(state, data) {
            state.allUsersList = data;
        },
        setChilds(state, data) {
            state.allChildsList = data;
        },
        setFiles(state, data) {
            state.allFilesList = data;
        },
        setBaseUrl(state, data) {
            state.baseUrl = data;
        },
        setEntity(state, data) {
            state.entity = data;
        },
        setData(state, data) {
            state.dataSave = data;
        },
        toggleCalendar(state, data) {
            state.showCalendar = data;
        },
        toggleGardeCalendar(state, data) {
            state.showCalendarGardes = data;
        },
        toggleFilesCalendar(state, data) {
            state.showCalendarFiles = data;
        },
        toggleIgnoreWarning(state, data) {
            state.ignoreWarning = data;
        },
        setAllForumPostsList(state, data) {
            state.allForumPostsList = data;
        },
        setIsForumNewPosts(state, data) {
            state.isForumNewPosts = data;
        },
        setActiveForumNewPostsList(state, data) {
            state.activeForumNewPostsList = data;
        },
        setIsForumNewPostComments(state, data) {
            state.isForumNewPostComments = data;
        },
        setActiveForumNewPostCommentsList(state, data) {
            state.activeForumNewPostCommentsList = data;
        },
    },
    // plugins: [createPersistedState({
    //   key: 'app'
    // })],
});

export default store;
